<template>
  <div class="page page--welcome animated_content">
    <sequential-entrance animation="animate__fadeInUp" tag="div">
      <StickyHeader class="animate__animated" title="Załóż konto"/>
      <div class="box-container animate__animated">
        <div class="box-content">
          <sequential-entrance animation="animate__fadeInUp" tag="div">
            <div class="animate__animated">
              <p>Twoje dane</p>
            </div>
            <div class="animate__animated">
              <TextField
                  v-model="form"
                  @input="validateForm"
                  name="first_name"
                  label="Imię"
                  placeholder="Imię"
                  required
              />
            </div>
            <div class="animate__animated">
              <TextField
                  v-model="form"
                  @input="validateForm"
                  name="last_name"
                  label="Nazwisko"
                  placeholder="Nazwisko"
                  required
              />
            </div>
            <div class="animate__animated">
              <TextField
                  v-model="form"
                  @input="validateForm"
                  name="email"
                  label="E-mail"
                  placeholder="E-mail"
                  inputType="email"
                  required
              />
            </div>
            <div class="animate__animated">
              <TextField
                  v-model="form"
                  v-mask="'### ### ###'"
                  @input="validateForm"
                  name="phone"
                  label="Numer telefonu"
                  placeholder="Numer telefonu"
                  inputType="tel"
                  required
              />
            </div>
            <div class="animate__animated">
              <TextField
                  v-model="form"
                  @input="validateForm"
                  name="password"
                  label="Hasło"
                  placeholder="Hasło"
                  inputType="password"
                  required
              />
            </div>
            <div class="animate__animated">
              <TextField
                  v-model="form"
                  @input="validateForm"
                  name="password_confirm"
                  label="Powtórz hasło"
                  placeholder="Powtórz hasło"
                  inputType="password"
                  required
              />
            </div>
            <div class="animate__animated">
              <CheckboxField
                  v-model="form"
                  @input="validateForm"
                  name="regulations"
                  :label="`Oświadczam, że znam i akceptuję postanowienia <a href='${$constants.regulations}' target='_blank'>Regulaminu Fastcat</a>`"
                  required
              />
            </div>
            <div class="animate__animated">
              <CheckboxField
                  v-model="form"
                  name="self_marketing"
                  label="<strong>Zamówienia</strong> - Wyrażam zgodę na przetwarzanie moich danych osobowych w celach marketingowych i otrzymywanie informacji handlowych od Fastcat. z wykorzystaniem środków komunikacji elektronicznej (m.in. e-mail)."
              />
            </div>
            <div class="animate__animated">
              <CheckboxField
                  v-model="form"
                  name="other_marketing"
                  label="<strong>Promocje</strong> - Wyrażam zgodę na przetwarzanie moich danych osobowych w celach marketingowych i otrzymywanie informacji handlowych od podmiotów współpracujących z Fastcat z wykorzystaniem środków komunikacji elektronicznej (m.in. e-mail)."
              />
            </div>
            <div class="animate__animated">
              <Btn @click="register" :disabled="!formIsValid">Utwórz konto</Btn>
            </div>
            <div class="animate__animated">
              <p style="text-align: left; font-size: 13px;"><span class="required-star">*</span> Pole wymagane</p>
            </div>
          </sequential-entrance>
        </div>
      </div>
      <div class="flex-fill animate__animated"></div>
      <PlainFooter class="animate__animated"/>
    </sequential-entrance>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
import Btn from "@/components/Btn";
import StickyHeader from "@/parts/StickyHeader";
import PlainFooter from "@/parts/PlainFooter";
import TextField from "@/components/TextField";
import CheckboxField from "@/components/CheckboxField";
import { VueMaskDirective } from 'v-mask';

export default {
  name: "Register",
  components: {CheckboxField, TextField, PlainFooter, StickyHeader, Logo, Btn},
  directives: {
    mask: VueMaskDirective
  },
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        password_confirm: '',
        regulations: false,
        self_marketing: false,
        other_marketing: false,
      },
      formIsValid: false,
    };
  },
  methods: {
    openInNewCard(link) {
      window.open(link, '_blank');
    },
    validateForm() {
      let ret = true;
      if (!this.form.first_name) {
        ret = false;
      }
      if (!this.form.last_name) {
        ret = false;
      }
      if (!this.form.email) {
        ret = false;
      } else if (!(/(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/.test(this.form.email))) {
        ret = false;
      }
      if (!this.form.phone) {
        ret = false;
      } else if (!(/^[\+]?[(]?[0-9]{3}[)]?[\s]?[0-9]{3}[\s]?[0-9]{3,6}$/gmi.test(this.form.phone))) {
        ret = false;
      }
      if (!this.form.password) {
        ret = false;
      } else if (this.form.password.length < 6) {
        ret = false;
      }
      if (!this.form.password_confirm) {
        ret = false;
      } else if (this.form.password !== this.form.password_confirm) {
        ret = false;
      }
      if (!this.form.regulations) {
        ret = false;
      }
      this.formIsValid = ret;
      return ret;
    },
    register() {
      if (!this.validateForm()) {
        this.$toast.error(`Formularz zawiera błędy`);
        return;
      }
      this.$store.dispatch('registerUser', { form: this.form }).then(({ statusCode, message }) => {
        if (statusCode === 200) {
          this.$toast.success(`<strong>Hej, ${this.form.first_name}!</strong><br>Twoje konto zostało pomyślnie utworzone, możesz się teraz zalogować.`);
          this.$router.push({ name: 'Login' });
        } else {
          this.$toast.error(`<strong>Upsss</strong><br>${message}`);
        }
      });
    },
  },
}
</script>

<style scoped lang="scss">
.box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  .box-content {
    padding: 0 15px 15px 15px;
    max-width: 600px;
    width: 100%;
    border-radius: var(--border-radius);
    border-width: 1px;
    border-style: solid;
    border-color: var(--border);
  }
}
.flex-fill {
  flex: 1;
}
</style>
